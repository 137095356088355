import { getEnvShared } from "@/shared/lib/get-env"
import { User } from "oidc-client-ts"

export const REST_ENDPOINT = getEnvShared("EMS_FRONTEND_REST_ENDPOINT")

export const REST_ENABLE_OAUTH = getEnvShared("EMS_FRONTEND_REST_ENABLE_OAUTH") === "true"
export const REST_ENABLE_API_TOKEN = getEnvShared("EMS_FRONTEND_REST_ENABLE_API_TOKEN") === "true"
export const REST_API_TOKEN = getEnvShared("EMS_FRONTEND_REST_API_TOKEN")

// Timeout for requests
export const TIMEOUT = 10000

// How often data is re-fetched from the API
export const PERIODIC_LOADING_INTERVAL = 30000
export const CONNECTION_CHECK_INTERVAL = 30000
export const CONNECTION_ERROR_NOTIFICATION_ID = "connectionCheck_error"

// See: https://github.com/authts/react-oidc-context?tab=readme-ov-file#call-a-protected-api
const SESSION_STORAGE_OIDC_KEY = `oidc.user:${getEnvShared("EMS_FRONTEND_OAUTH_AUTHORITY")}:${getEnvShared("EMS_FRONTEND_OAUTH_CLIENT_ID")}`

export function getUser() {
  const oidcStorage = localStorage.getItem(SESSION_STORAGE_OIDC_KEY)
  if (!oidcStorage) {
    return null
  }

  return User.fromStorageString(oidcStorage)
}

/**
 * A wrapper for `fetch` to trigger requests on the EMS REST API.
 *
 * This will:
 * - handle authentication
 * - handle service location (URL) so that you only have to use local relative URLs e.g. "/system/ping" instead of "https://<server>:<port>/system/ping"
 */
export async function fetchApi(input: string, init?: RequestInit, disableAuth?: boolean) {
  const headers: HeadersInit = {}

  if (!disableAuth) {
    if (REST_ENABLE_OAUTH) {
      const user = getUser()
      if (!user) {
        throw Error("Cannot find OAuth info in session storage")
      }

      const token = user.access_token

      headers["Authorization"] = `Bearer ${token}`
    } else if (REST_ENABLE_API_TOKEN) {
      headers["Authorization"] = `Apitoken ${REST_API_TOKEN}`
    }
  }

  const abortController = new AbortController()

  const timeout = setTimeout(() => abortController.abort(), TIMEOUT)

  const response = await fetch(`${REST_ENDPOINT}${input}`, {
    ...init,
    headers: {
      ...init?.headers,
      ...headers,
    },
    signal: init?.signal ?? abortController.signal,
  })
  clearTimeout(timeout)
  return response
}

export function prepareHeaders(headers: Headers) {
  if (REST_ENABLE_OAUTH) {
    const user = getUser()
    if (!user) {
      throw Error("Cannot find OAuth info in session storage")
    }

    const token = user.access_token

    headers.set("Authorization", `Bearer ${token}`)
  } else if (REST_ENABLE_API_TOKEN) {
    headers.set("Authorization", `Apitoken ${REST_API_TOKEN}`)
  }
}
