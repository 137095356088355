import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { prepareHeaders, REST_ENDPOINT, TIMEOUT } from "@/shared/lib/dataloading/fetch-util"

export const api = createApi({
  tagTypes: ["devices", "tasks", "device-details", "connection"],
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${REST_ENDPOINT}`,
    prepareHeaders,
    timeout: TIMEOUT,
  }),
  endpoints: () => ({}),
})
